import Utils from '../utils';
import Constants from '../constants';

export default class Core {
  constructor() {
    window.initCiblerCore = () => {
      window.removeEventListener('mouseover', window.initCiblerCore);
      window.removeEventListener('scroll', window.initCiblerCore);
      clearTimeout(this.timer);
      setTimeout(() => {
        this.init();
      }, 1000);
    };

    const ciblerTriggerCampaign = Utils.getQueryString('ciblerTriggerCampaign');
    const triggerUserGroup = Utils.getQueryString('triggerUserGroup');

    if (ciblerTriggerCampaign || triggerUserGroup) {
      this.clearLocalData(); // Clear lock when triggered
      setTimeout(() => {
        window.initCiblerCore();
      }, 100);
    } else {
      Utils.ciblerready(() => {
        if (Utils.getQueryString('cibler_auth')) {
          window.initCiblerCore();
        } else {
          this.timer = setTimeout(() => {
            window.initCiblerCore();
          }, 1000);
          window.addEventListener('mouseover', window.initCiblerCore, { passive: true });
          window.addEventListener('scroll', window.initCiblerCore, { passive: true });
        }
      });
    }
  }

  clearLocalData = () => {
    localStorage.removeItem('ciblerDailyLock');
    localStorage.removeItem('ciblerDailyCampaignLock');
  };

  init() {
    const config = sessionStorage.getItem('ciblerConfig');
    if (config && Utils.isStringJson(config)) {
      this.initCallback(config);
      return;
    }

    Utils.getAjax(
      `${Constants.ENDPOINT.RENDER.CACHE}/api/customers/${this.customerId}/configuration`,
      this.initCallback,
      () => undefined,
    );
  }

  initCallback = (result) => {
    const config = JSON.parse(result);

    sessionStorage.setItem('ciblerConfig', result);

    if (!localStorage.getItem('ciblerOpeningRate')) {
      localStorage.setItem('ciblerOpeningRate', Math.random() * 100);
    }

    const openRand = parseFloat(localStorage.getItem('ciblerOpeningRate'));
    window.WIOCONFIG = {
      ...window.WIOCONFIG,
      ...config,
      openRand,
    };

    if (config.openingRate === null || openRand < config.openingRate) {
      this.insertTracking(config);
      this.insertRender(config);
    }
  };

  insertTracking = (config) => {
    let url = `${Constants.ENDPOINT.CDN}/static/${this.customerId}/`;
    if (config.trackingVersion) {
      url = `${Constants.ENDPOINT.CDN}/static/`;
      if (config.customTracking) {
        url = `${url}${this.customerId}/`;
      } else {
        url = `${url}common/`;
      }
      url = `${url}${config.trackingVersion}/`;
    }

    window.WIOCONFIG.trackingUrl = url;
    const s = document.createElement('script');
    s.setAttribute('id', 'ciblerTrackingScript');
    s.setAttribute('src', `${url}wiotracking.js`);
    document.head.appendChild(s);
  };

  insertRender = (config) => {
    let url = `${Constants.ENDPOINT.RENDER.CDN}/static/${this.customerId}/`;
    if (config.renderVersion) {
      url = `${Constants.ENDPOINT.RENDER.CDN}/static/`;
      if (config.customRender) {
        url = `${url}${this.customerId}/`;
      } else {
        url = `${url}common/`;
      }
      url = `${url}${config.renderVersion}/`;
    }

    window.WIOCONFIG.renderUrl = url;
    const s = document.createElement('script');
    s.setAttribute('id', 'ciblerRenderScript');
    s.setAttribute('src', `${url}wiorender.js`);
    document.head.appendChild(s);
  };
}
